import { useEffect } from "react";
import {Helmet} from "react-helmet";

const LegalFr = ({page, title}) => {
    
    useEffect(() => {
        page(6) 
        title('Mentions légales de Matthieu Sibert International')
       }, [])


    return ( 
        <main className="Legal">
             <Helmet htmlAttributes={{lang:"fr"}}>
                <title>Matthieu Sibert International - Mentions légales</title>
                <meta name="description" content="Mentions légales de Matthieu Sibert International" />
                <link rel="canonical" href="https://www.matthieu-sibert-international.me/Fr/Mentions-légales"/>
            </Helmet>
            <section>
                <h2>Conditions d'utilisation</h2>
                <article>
                    <h3>1. Introduction</h3>
                    <p>Bienvenue chez Matthieu Sibert International ("Société", "nous", "notre" ou "nos"). Ces Conditions d'utilisation ("Conditions") régissent votre utilisation de notre site Web, applications mobiles, logiciels et tout service associé (collectivement, le "Service"). En utilisant notre Service, vous acceptez ces Conditions. Si vous n'êtes pas d'accord avec une partie de ces Conditions, n'utilisez pas notre Service.</p>
                </article>
                <article>
                    <h3>2. Comptes utilisateurs</h3>
                    <p>Pour accéder à certaines fonctionnalités du Service, vous devrez peut-être créer un compte. Vous acceptez de fournir des informations exactes, actuelles et complètes lors du processus d'inscription et de maintenir à jour vos informations de compte. Vous êtes responsable de maintenir la confidentialité de vos informations de compte et de toutes les activités qui se produisent sous votre compte. Vous devez nous informer immédiatement de toute utilisation non autorisée de votre compte.</p>
                </article>
                <article>
                    <h3>3. Utilisation acceptable</h3>
                    <p>Vous acceptez d'utiliser le Service conformément à toutes les lois et réglementations applicables. Vous ne devez pas :</p>
                    <ul>
                        <li>Utiliser le Service à des fins illégales ou non autorisées.</li>
                        <li>Télécharger ou transmettre tout contenu qui est illégal, diffamatoire, obscène ou autrement répréhensible.</li> 
                        <li>Participer à toute activité pouvant nuire au Service ou interférer avec l'utilisation du Service par d'autres utilisateurs.</li>
                        <li>Tenter d'accéder sans autorisation à nos systèmes ou réseaux.</li>
                    </ul>
                </article>
                <article>
                    <h3>4. Propriété intellectuelle</h3>
                    <p>Tous les contenus, marques commerciales, logos et autres propriétés intellectuelles affichés sur le Service sont la propriété de Matthieu Sibert International ou de ses concédants de licence. Vous acceptez de ne pas utiliser, reproduire, distribuer ou créer des œuvres dérivées basées sur notre propriété intellectuelle sans notre consentement écrit préalable.</p>
                </article>
                <article>
                    <h3>5. Confidentialité</h3>
                    <p>Notre Politique de confidentialité décrit comment nous collectons, utilisons et divulguons les informations des utilisateurs du Service. En utilisant le Service, vous acceptez les termes de notre Politique de confidentialité.</p>
                </article>
                <article>
                    <h3>6. Liens vers des tiers</h3>
                    <p>Le Service peut contenir des liens vers des sites Web ou des services tiers. Nous n'avalisons ni n'assumons aucune responsabilité pour ces sites tiers. Si vous accédez à un site tiers, vous le faites à vos propres risques, et ces Conditions ne s'appliquent plus.</p>
                </article>
                <article>
                    <h3>7. Exclusion de garanties</h3>
                    <p>Le Service est fourni "tel quel" et "tel que disponible". Nous déclinons toutes les garanties, qu'elles soient expresses ou implicites, y compris toute garantie implicite de qualité marchande, d'adéquation à un usage particulier et de non-violation. Nous ne garantissons pas que le Service sera exempt d'erreurs ou ininterrompu.</p>
                </article>
                <article>
                    <h3>8. Limitation de responsabilité</h3>
                    <p>Dans la mesure maximale permise par la loi, Matthieu Sibert International ne sera pas responsable des dommages directs, indirects, accessoires, spéciaux ou consécutifs découlant de votre utilisation du Service, même si nous avons été informés de la possibilité de tels dommages.</p>
                </article>
                <article>
                    <h3>9. Indemnisation</h3>
                    <p>Vous acceptez d'indemniser et de dégager de toute responsabilité Matthieu Sibert International, ses sociétés affiliées, ses dirigeants, ses administrateurs et ses employés contre toute réclamation, dommage, responsabilité ou frais découlant de votre utilisation du Service ou de la violation de ces Conditions.</p>
                </article>
                <article>
                    <h3>10. Résiliation</h3>
                    <p>Nous nous réservons le droit de résilier ou de suspendre votre accès au Service à tout moment, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris la violation de ces Conditions.</p>
                </article>
                <article>
                    <h3>11. Droit applicable</h3>
                    <p>Ces Conditions sont régies par et interprétées conformément aux lois de l'Union européenne. Toute action en justice ou procédure découlant de ou liée à ces Conditions ou au Service doit être intentée exclusivement devant les tribunaux situés dans l'Union européenne.</p>
                </article>
                <article>
                    <h3>12. Modifications des Conditions</h3>
                    <p>Nous nous réservons le droit de mettre à jour ou de modifier ces Conditions à tout moment. Si nous apportons des modifications substantielles, nous vous informerons par e-mail ou via le Service. En continuant à utiliser le Service après les modifications, vous acceptez les Conditions révisées.</p>
                </article>
                <article>
                    <h3>13. Informations de contact</h3>
                    <p>Si vous avez des questions ou des préoccupations concernant ces Conditions, veuillez nous contacter à <a href="mailto:legal@matthieu-sibert-international.me?subject=Conditions d'utilisation de Matthieu Sibert International">legal@matthieu-sibert-international.me</a></p>
                </article>
            </section>
            <hr/>
            <section>
                <h2>Politique de confidentialité</h2>
                <article>
                    <h3>1. Introduction</h3>
                    <p>Chez Matthieu Sibert International ("Société", "nous", "notre" ou "nos"), nous respectons votre vie privée et nous nous engageons à protéger vos informations personnelles. Cette Politique de confidentialité décrit comment nous collectons, utilisons, divulguons et protégeons vos informations lorsque vous utilisez notre site Web, applications mobiles, logiciels ou tout autre service associé (collectivement, le "Service"). En accédant ou en utilisant notre Service, vous acceptez les termes de cette Politique de confidentialité.</p>
                </article>
                <article>
                    <h3>2. Informations que nous collectons</h3>
                    <h4>2.1 Informations personnelles</h4>
                    <p>Nous pouvons collecter des informations personnelles que vous fournissez volontairement, telles que votre nom, adresse e-mail, numéro de téléphone, adresse postale ou autres coordonnées. Ces informations sont collectées lorsque vous créez un compte, nous contactez ou utilisez certaines fonctionnalités de notre Service.</p>
                    <h4>2.2 Données d'utilisation</h4>
                    <p>Nous pouvons collecter des informations sur la manière dont vous utilisez notre Service, telles que les pages que vous visitez, le temps passé sur ces pages et d'autres données analytiques. Ces informations nous aident à comprendre comment les utilisateurs interagissent avec notre Service et à l'améliorer au fil du temps.</p>
                    <h4>2.3 Informations sur l'appareil</h4>
                    <p>Nous pouvons collecter des informations sur l'appareil que vous utilisez pour accéder à notre Service, y compris le type d'appareil, le système d'exploitation, le type de navigateur et les identifiants d'appareil uniques.</p>
                </article>
                <article>
                    <h3>3. Utilisation de vos informations</h3>
                    <p>Nous pouvons utiliser vos informations à diverses fins, notamment :</p>
                    <ul>
                        <li>Pour exploiter, maintenir et améliorer notre Service.</li>
                        <li>Pour traiter les transactions et vous envoyer des informations connexes, telles que des confirmations et des factures.</li>
                        <li>Pour répondre à vos demandes et fournir un support client.</li>
                        <li>Pour vous envoyer des communications marketing, avec votre consentement.</li>
                        <li>Pour détecter et prévenir la fraude ou l'accès non autorisé.</li>
                    </ul>
                </article>
                <article>
                <h3>4. Divulgation de vos informations</h3>
                <p>Nous pouvons partager vos informations dans les circonstances suivantes :</p>
                <ul>
                    <li>Avec des prestataires de services tiers qui fournissent des services en notre nom, tels que le traitement des paiements, l'hébergement ou l'analyse.</li>
                    <li>Pour respecter les obligations légales ou répondre aux demandes légales des autorités gouvernementales.</li>
                    <li>Pour protéger nos droits, notre propriété ou notre sécurité, ou les droits, la propriété ou la sécurité d'autrui.</li>
                    <li>Dans le cadre d'une transaction commerciale, telle qu'une fusion, une acquisition ou une vente d'actifs.</li>
                </ul>
                </article>
                <article>
                    <h3>5. Cookies et technologies similaires</h3>
                    <p>Nous utilisons des cookies et des technologies similaires pour collecter des informations sur votre utilisation de notre Service. Les cookies sont de petits fichiers texte qui sont stockés sur votre appareil. Vous pouvez configurer votre navigateur pour refuser les cookies, mais cela peut affecter votre capacité à utiliser certaines fonctionnalités de notre Service.</p>
                </article>
                <article>
                    <h3>6. Vos droits et choix</h3>
                    <p>En fonction de votre juridiction, vous pouvez avoir certains droits concernant vos informations personnelles, notamment :</p>
                    <ul>
                        <li>Le droit d'accéder, de corriger ou de supprimer vos informations personnelles.</li>
                        <li>Le droit de vous opposer à certains types de traitement ou de les restreindre.</li>
                        <li>Le droit de retirer votre consentement, le cas échéant.</li>
                    </ul>
                    <p>Pour exercer l'un de ces droits, veuillez nous contacter à l'adresse suivante : <a href="mailto:legal@matthieu-sibert-international.me?subject=Conditions d'utilisation de Matthieu Sibert International">legal@matthieu-sibert-international.me</a></p>
                </article>
                <article>
                    <h3>7. Sécurité des données</h3>
                    <p>Nous mettons en œuvre des mesures de sécurité raisonnables pour protéger vos informations personnelles contre tout accès, divulgation, altération ou destruction non autorisés. Cependant, aucun système de sécurité n'est complètement infaillible, et nous ne pouvons garantir la sécurité absolue de vos informations.</p>
                </article>
                <article>
                    <h3>8. Conservation des données</h3>
                    <p>Nous conservons vos informations personnelles aussi longtemps que nécessaire pour remplir les finalités décrites dans cette Politique de confidentialité, sauf si une période de conservation plus longue est exigée ou autorisée par la loi.</p>
                </article>
                <article>
                    <h3>9. Confidentialité des enfants</h3>
                    <p>Notre Service n'est pas destiné aux enfants de moins de 13 ans. Nous ne collectons pas sciemment d'informations personnelles auprès d'enfants de moins de 13 ans. Si nous apprenons que nous avons collecté de telles informations, nous prendrons des mesures pour les supprimer.</p>
                </article>
                <article>
                    <h3>10. Modifications de cette Politique de confidentialité</h3>
                    <p>Nous nous réservons le droit de mettre à jour ou de modifier cette Politique de confidentialité à tout moment. Si nous apportons des modifications importantes, nous vous en informerons par e-mail ou via le Service. En continuant à utiliser le Service après les modifications, vous acceptez la politique révisée.</p>
                </article>
                <article>
                    <h3>11. Informations de contact</h3>
                    <p>Si vous avez des questions ou des préoccupations concernant cette Politique de confidentialité, veuillez nous contacter à l'adresse suivante : <a href="mailto:legal@matthieu-sibert-international.me?subject=Conditions d'utilisation de Matthieu Sibert International">legal@matthieu-sibert-international.me</a></p>
                </article>
            </section>
        </main>
     );
}
 
export default LegalFr;