import { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'

import Home from '../screens/Home'
import Contact from '../screens/Contact'
import About from '../screens/About'
import Legal from '../screens/Legal'

import HomeFr from '../screens/HomeFr'
import AboutFR from '../screens/AboutFr'
import LegalFr from '../screens/LegalFr'
import ContactFr from '../screens/ContactFr'

import HomeBackground from '../images/HomeBackground.png'
import AboutBackground from '../images/AboutBackground.png'
import LegalBackground from '../images/LegalBackground.png'
import ContactBackground from '../images/ContactBackground.png'

const Nav = () => {

    const [page, setPage] = useState(0)
    const [title, setTitle] = useState('A Multifaceted Journey through Matthieu Sibert International')
    const [background, setBackground] = useState(HomeBackground)
    const [animated, setAnimated] = useState(false)
    const [translation, setTranslation] = useState('')
    const [menu, setMenu] = useState(['About','/About','Legal','/Legal','Contact','/Contact','Version française', 'France','/'])
    const [intro, setIntro] = useState('Welcome to the world of Matthieu Sibert International, where innovation knows no bounds. Today, we embark on a journey that explores the dynamic realms of mobile apps, websites, data science, finance and video games.')

    const changePage = (x) => {
        setPage(x)
    }

    const changeTitle = (x) => {
        setTitle(x)
    }    

    useEffect(() => {
        if(page===0) {
            setBackground(HomeBackground)
            setTranslation('/FR/Acceuil')}
        if(page===1) {
            setBackground(AboutBackground)
            setTranslation('/FR/A-propos')}
        if(page===2) {
            setBackground(LegalBackground)
            setTranslation('/FR/Mentions-légales')}
        if(page===3) {
            setBackground(ContactBackground)
            setTranslation('/FR/Contact')}
        if(page==4){
            setBackground(HomeBackground)
            setTranslation('/')
        }
        if(page==5){
            setBackground(AboutBackground)
            setTranslation('/About')
        } if(page==6){
            setBackground(LegalBackground)
            setTranslation('/Legal')
        } if(page==7){
            setBackground(ContactBackground)
            setTranslation('/Contact')
        }
        if(page<4){
            setMenu(['About','/About','Legal','/Legal','Contact','/Contact','Version française', 'France','/'])
            setIntro('Welcome to the world of Matthieu Sibert International, where innovation knows no bounds. Today, we embark on a journey that explores the dynamic realms of mobile apps, websites, data science, finance and video games.')
        }else{
            setMenu(['A propos','/FR/A-propos','Mentions légales','/FR/Mentions-légales','Contact','/FR/Contact','English version', 'usa','/FR/Acceuil'])
            setIntro('Bienvenue dans le monde de Matthieu Sibert International, où l’innovation ne connaît pas de limites. Aujourd’hui, nous entreprenons un voyage qui explore les domaines dynamiques des applications mobiles, des sites Web, de la science des données, de la finance et des jeux vidéo.')
        }
        setAnimated(true)
        setTimeout(() => {
            setAnimated(false);
        }, 300);
    }, [page])    

    const back = () => {
        document.getElementById('menu').setAttribute('style','display:none')
        document.getElementsByTagName('main')[0].setAttribute('style','display:block')
        document.getElementsByTagName('footer')[0].setAttribute('style','display:block')
        document.getElementsByTagName('h1')[0].setAttribute('style','display:block')
    }

    const make = () => {
        document.getElementById('menu').setAttribute('style','display:block')
        document.getElementsByTagName('main')[0].setAttribute('style','display:none')
        document.getElementsByTagName('footer')[0].setAttribute('style','display:none')
        document.getElementsByTagName('h1')[0].setAttribute('style','display:none')
    }
    return ( 
        <Router>
            <header style={{background: `url(${background})`}} id={animated ? 'appHeader':''} >
                <nav id='mobileNav'>
                    <img src={process.env.PUBLIC_URL + '/images/menu.png'} loading='eager' onClick={make} title='Menu icon' alt='Basic menu icon'/>
                    <ul id='menu'>
                        <li><Link to={menu[8]} onClick={back}><img src={process.env.PUBLIC_URL + '/images/1x/logo.png'} loading='eager' id='mobileLogo' title="Logo of Matthieu Sibert International" alt="Logo of Matthieu Sibert International which is represented by the moon inside a pyramidal blue structure"/></Link></li>
                        <hr/>
                        <li><Link to={menu[1]} onClick={back}>{menu[0]}</Link></li>
                        <li><Link to={menu[3]}onClick={back}>{menu[2]}</Link></li>
                        <li><Link to={menu[5]} onClick={back}>{menu[4]}</Link></li>
                        <hr/>
                        <li><Link to={translation} style={{textTransform:'capitalize'}}onClick={back}><img src={process.env.PUBLIC_URL + '/images/'+menu[7]+'.png'} loading='eager' id='mobileLang' title='Translation flag' alt={menu[7] + ' flag'}/> </Link></li>
                        <li><Link style={{textTransform:'capitalize'}} onClick={back}>Back</Link></li>
                    </ul>
                </nav>
                <nav id='navigation'>
                    <Link to={menu[8]}><img src={process.env.PUBLIC_URL + '/images/1x/logo.png'} className='logo' loading='eager' title="Logo of Matthieu Sibert International" alt="Logo of Matthieu Sibert International which is represented by the moon inside a pyramidal blue structure"/></Link>
                    <ul>
                        <li>-</li>
                        <li><Link to={menu[1]}>{menu[0]}</Link></li>
                        <li><Link to={menu[3]}>{menu[2]}</Link></li>
                        <li><Link to={menu[5]}>{menu[4]}</Link></li>
                        <li><Link to={translation}><img src={process.env.PUBLIC_URL + '/images/'+menu[7]+'.png'} title='Translation flag' alt={menu[7] + ' flag'}/> {menu[6]}</Link></li>
                        <li>-</li>
                    </ul>
                </nav>
                <div className='headerApp'>
                    <h1 id={animated ? 'appTitle':''}>{title}</h1>
                    <p id='intro'>{intro}</p>
                </div>
            </header>
            <Routes>
                <Route path="/" element={<Home page={changePage} title={changeTitle} />}/>
                <Route path="Contact" element={<Contact page={changePage} title={changeTitle} />} />
                <Route path="About" element={<About page={changePage} title={changeTitle}/>} />
                <Route path="Legal" element={<Legal page={changePage} title={changeTitle} />} />
                <Route path='/FR'>
                    <Route path='Acceuil' element={<HomeFr page={changePage} title={changeTitle}/>}/>
                    <Route path='A-propos' element={<AboutFR page={changePage} title={changeTitle}/>}/>
                    <Route path='Mentions-légales' element={<LegalFr page={changePage} title={changeTitle}/>}/>
                    <Route path='Contact' element={<ContactFr page={changePage} title={changeTitle}/>}/>
                </Route>
            </Routes>
        </Router>
    );
}
 
export default Nav;
