import { useEffect } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

const HomeFr = ({page, title}) => {

    useEffect(() => {
      page(4)
      title('Un voyage aux multiples facettes à travers Matthieu Sibert International')
    }, [])
    

    return ( 
        <main className="home">
            <Helmet htmlAttributes={{lang:"fr"}}>
                <title>Matthieu Sibert International - Homepage</title>
                <meta name="description" content="Bienvenue dans le monde de Matthieu Sibert International, où l’innovation ne connaît pas de limites. Aujourd’hui, nous entreprenons un voyage qui explore les domaines dynamiques des applications mobiles, des sites Web, de la science des données, de la finance et des jeux vidéo.lcome to the world of Matthieu Sibert International, where innovation knows no bounds. Today, we embark on a journey that explores the dynamic realms of mobile apps, websites, data science, finance and video games." />
                <link rel="canonical" href="https://www.matthieu-sibert-international.me/FR/Acceuil"/>
            </Helmet>
            <section>
                <h2>Découvrez une large gamme de produits étonnants</h2>
                <article>
                    <h3>Applications mobiles</h3>
                    <hr/>
                    <img src={process.env.PUBLIC_URL + '/images/1x/mobileApp.png'} title="Logo des applications mobiles" alt="Un téléphone portable sombre avec le logo de Matthieu Sibert International"/>
                    <div>
                        <p>À l'ère de la connectivité nomade, nos applications mobiles sont synonymes de commodité et d'efficacité. Des interfaces utilisateur intuitives aux fonctionnalités transparentes, chaque application que nous créons témoigne de notre engagement à améliorer l'expérience utilisateur. Qu'il s'agisse de rationaliser les tâches quotidiennes ou de favoriser l'engagement, nos applications mobiles permettent aux utilisateurs de naviguer facilement dans leur vie numérique.</p>
                    </div>
                </article>
                <article>
                    <h3>Sies Web</h3>
                    <hr/>
                    <div>
                        <p>Dans le domaine virtuel, les sites Web servent de passerelles à l'identité de votre marque. Chez Matthieu Sibert International, nous nous spécialisons dans le tissage de récits numériques qui captivent et inspirent. Des conceptions épurées aux infrastructures d'arrière-plan robustes, nos sites Web sont conçus pour laisser une impression durable. Avec un œil attentif sur l'expérience utilisateur et les technologies de pointe, nous transformons les concepts en expériences en ligne captivantes.</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/images/1x/website.png'} title="Logo des sites web" alt="Un ordinateur sombre avec le logo de Matthieu Sibert International"/>
                </article>
                <article>
                    <h3>Data science</h3>
                    <hr/>
                    <img src={process.env.PUBLIC_URL + '/images/1x/dataScience.png'} title="Logo de la data science" alt="Un robot blanc"/>
                    <div>
                        <p>À l'ère du Big Data, les insights règnent en maître. En exploitant le pouvoir de la science des données, nous débloquons des potentiels cachés et dévoilons des insights exploitables. Grâce à l'analyse avancée et à la modélisation prédictive, nous permettons aux entreprises de prendre des décisions éclairées, d'optimiser les processus et de garder une longueur d'avance. Des recommandations personnalisées à la maintenance prédictive, nos solutions axées sur les données favorisent l'efficacité et la croissance.</p>
                    </div>
                </article>
                <article>
                    <h3>Jeux vidéo</h3>
                    <hr/>
                    <img src={process.env.PUBLIC_URL + '/images/1x/videoGames.png'} title="Logo des jeux videos" alt="Un manette fantaisiste de console de jeux videos"/>
                    <div>
                        <p>Le divertissement ne connaît pas de limites dans le domaine des jeux vidéo. Chez Matthieu Sibert International, nous exploitons le pouvoir du jeu pour créer des expériences immersives qui transcendent les frontières. Avec un mélange de créativité et de prouesses techniques, nous redéfinissons le divertissement interactif et repoussons les limites de l'imagination.</p>
                    </div>
                </article>
                <article>
                    <h3>Finance</h3>
                    <hr/>
                    <div>
                        <p>Découvrez une gamme de produits financiers innovants conçus pour répondre à vos divers besoins. Des options de placement personnalisées aux solutions complètes de gestion des risques, nos offres visent à vous aider à atteindre vos objectifs financiers en toute confiance. Collaborez avec nous pour obtenir des conseils d'experts et des outils financiers flexibles.</p>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/images/1x/finance.png'} title="Logo des services financiers" alt="Un graphique financier 3D"/>
                </article>
            </section>
            <p style={{textAlign:'center'}}>Pour toute question, collaboration ou pour explorer davantage notre portefeuille, n'hésitez pas à <Link to='/FR/Contact'>nous contacter</Link>.</p>
        </main>
     );
}
 
export default HomeFr;