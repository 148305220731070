import { Link } from "react-router-dom";

const Footer = () => {

    const a = new Date()
    const b = a.getFullYear()


    return ( 
        <footer>
            <p style={{textAlign:'left'}}><a target='blank' href='https://www.linkedin.com/company/matthieu-sibert-international'>Follow us on LinkedIn</a></p>
            <p style={{textAlign:'left'}}><a target='blank' href='https://www.x.com/MattSibInter'>Follow us on X</a></p>
            <p style={{textAlign:'left'}}><a target='blank' href='https://www.facebook.com/61559697242824/'>Follow us on the Facebook</a></p>
            <p style={{textAlign:'left'}}><a target='blank' href='https://www.tiktok.com/@matthieusibert'>Follow us on Tik Tok</a></p>
            <br/>
            <br/>
            <img src={process.env.PUBLIC_URL + '/images/1x/logo.png'} title="Logo of Matthieu Sibert International" alt="Logo of Matthieu Sibert International which is represented by the moon inside a pyramidal blue structure" loading="lazy"/>
            <p style={{margin:0}}>Matthieu Sibert International {b} All rights reserved</p>
        </footer>
     );
}
 
export default Footer;